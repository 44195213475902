.border-left {
    border-radius: 18px 0px 0px 18px;
}

.border-right {
    border-radius: 0px 18px 18px 0px;
}

@media only screen and (max-width: 1920px) {
}

@media only screen and (max-width: 1320px) {
    .btn-freeTrialBottom {
        left: 25rem;
    }

}

@media only screen and (max-width: 1250px) {
    .btn-freeTrialBottom {
        left: 20rem;
    }
}

@media only screen and (max-width: 950px) {
    .img-customer {
        width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .border-left {
        border-radius: 18px 18px 0px 0px;
    }

    .content-block {
        padding: 0 !important;
    }

    .border-right {
        border-radius: 0px 0px 18px 18px;
    }

    .grid {
        flex-direction: column;
    }

    /* .btn-bottom {
        position: relative;
        padding: 10px;
        height: 40px;
        bottom: 15rem;
        top: -5rem;
        left: 14rem;
        width: 120px;
        font-size: 11px;
    } */

    .pic-customer {
        width: 100% !important;
    }

    .blackBlock {
        width: 100% !important;
    }

    .w-auto-responive {
        width: 100% !important;
    }

    .bottom-checks {
        margin: 1rem !important;
        gap: 2rem !important;
    }

}