.trialButton {
  background-color: #00BD9D;
  color: white;
  font-size: 15px;
  padding-top: 1rem;
  padding-bottom: 1rem;
  padding-right: 1rem;
  padding-left: 1rem;
  border-radius: 40px;
  cursor: pointer;
}

.button-edit-vert {
  font-size: 1.312rem;
  padding: 6px;
  border-radius: 4px;
  line-height: 1.3em;
  background-color: #78b40b;
}

.band {
  position: fixed;
  background-color: #e7f5f8;
  z-index: -1;
  width: 100%;
}

.button-edit {
  padding: 8px 10px;
  line-height: 1.3em;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
  background-color: #ff554b;
}

.fixed-header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
}

.header-content {
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);

}

button {
  border: none;
  color: white;
}