.App {
  text-align: center;
}

@media screen and (max-width: 576px) {
  .logo {
    width:25rem;
  }
}

.c-white{
  color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.center-content {
  justify-content: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.grecaptcha-badge { 
  visibility: hidden !important;
}

.width-content {
  max-width: 1300px;
  justify-content: right;
}

.content-center {
  margin: 0 auto;
  max-width: 1700px;

}

.content-center2 {
  margin: 0 auto;
  max-width: 1400px;
}

.content-center3 {
  margin: 0 auto;
  max-width: 1350px;
}

.p-button-label {
  font-weight: 400 !important;
}

.c-black{
  color: black;
}

Button {
  box-shadow: none !important;
}




@import url('https://fonts.googleapis.com/css2?family=Inter&family=Montserrat:wght@500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&display=swap');