.p-panel-header {
    background-color: white !important;
    border: none !important;
    font-size: 20px;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.p-panel-content {
    border: none !important;
    text-align: left;
    width: 900px;
}

.p-panel-icons {
    color: black;
    padding: 1px;
    border: 3px solid black;
    border-radius: 50px;
}

.p-panel {
    border: none !important;
}

.space {
    width: 1000px;
}

.faq-div{
    max-width: 100%;
}

.f-10 {
    font-size: 10px;
}

.custom-fieldset {
    text-align: justify;
    border: none;
    max-height: 0;
    overflow: hidden;
    transition: 0.5s ease-in, 0.5s ease-out;
}

.custom-fieldset.open {
    max-width: 1000px;
    max-height: 1000px;
    /* Vous pouvez ajuster cette valeur selon le contenu */
    padding: 1em;
    /* Ajustez la valeur du padding selon vos besoins */
}

.custom-fieldset.closed {
    padding: 0;
    max-width: 1000px;
}

.voir-conditions {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
    padding: 2rem;
    border-left: 3px solid black;
}


.p-panel-toggler {
    border: 3px solid black;
    font-weight: 700;
    color: black;
}

/* faq.css */

.faq-panel-fixe .p-panel-toggler:hover {
    background-color: #1757FF !important;
    border: none;
    color: white;
}

.faq-panel-standard .p-panel-toggler:hover {
    background-color: #00BD9D !important;
    border: none;
    color: white;
}

.faq-panel-pro .p-panel-toggler:hover {
    background-color: #0D081C !important;
    border: none;
    color: white;
}


.faq-content {
    margin-right: 15rem;
    margin-left: 15rem;
}

.trialButton {
    background-color: #00BD9D;
    color: white;
    height: 55px;
    border: none;
}


@media only screen and (max-width: 1320px) {
    .faq-content {
        margin: 0;
    }
}