.black-bg {
    background-color: rgb(5, 5, 5);
}

.devis {
    cursor: pointer;

}

.img-banner {
    width: 38vw;
}

.pack-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 4rem;
    margin-top: 8rem;
}

@media (max-width: 1000px) {
    .pack-content {
        flex-direction: column;
    }

    .pack-content>div {
        width: 100%;
    }

    .form-container {
        order: 1;
        /* Assurez-vous que le formulaire passe en dessous du contenu */
    }
}