body {
  font-weight: normal;
  min-height: 100vh;
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");


* {
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
}

.f-white {
  color: white;
}

a{
  text-decoration: none;
}

.glimmer-orange {
  content: '';
  padding: 1rem;
  border-radius: 50%;
  width: 10%;
  left: 0;
  height: 25rem;
  background-color: #FFA117;
  position: absolute;
  z-index: -1;
  filter: blur(110px); /* Ajoute l'effet de flou */
}

.glimmer-blue {
  content: '';
  padding: 1rem;
  border-radius: 50%;
  width: 10%;
  right: 0;
  height: 25rem;
  background-color: #24d7fb;
  position: absolute;
  z-index: -1;
  filter: blur(110px); /* Ajoute l'effet de flou */
}




@media (max-width: 900px) {
  .glimmer-orange-top,
  .glimmer-blue-top {
    display: none;
  }
  .glimmer-orange-down,
  .glimmer-blue-down {
    display: none;
  }
}

@media screen and (max-width: 576px) {
  body {
    max-width: 100%;
    margin: auto;
    padding: auto;
  }
}