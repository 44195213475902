.customers-container {
    overflow: hidden; /* Masquer les logos qui défilent hors du conteneur */
    width: 100%;
    padding: 10px 0; /* Espacement vertical */
}

.logos {
    display: flex;
    align-items: center;
    animation: scroll 50s linear infinite; /* Animation pour faire défiler les logos */
}

.logo {
    height: 100px; /* Taille des logos, ajustez selon vos besoins */
    margin-right: 10rem; /* Espacement entre les logos */
}

.logo-allianz{
    height: 80px;
}

.logo-cdos{
    height: 190px;
}

.logo-suez{
    height: 60px;
}

.logo-lynx {
    height: 180px; /* Taille spécifique pour lynx */
}

.logo-aquilarh {
    height: 180px; /* Taille spécifique pour aquilarh */
}

@keyframes scroll {
    0% {
        transform: translateX(0); /* Commence à droite */
    }
    100% {
        transform: translateX(-50%); /* Finit à gauche de la moitié */
    }
}
