.pic-size {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    /* Adjust if you want to keep the image within the container without stretching */
}

.pic-size-banner {
    width: 30vw;
    height: auto;
}

.w-600 {
    max-width: 600px;
}

.w-200 {
    max-width: 200px;
}

.maxW-100 {
    max-width: 100%;
}

.bo-pic {
    max-width: 100%;
    height: auto;
    object-fit: contain;
    /* Adjust if you want to keep the image within the container without stretching */
}

/* Media queries to adjust font sizes */
@media (max-width: 1200px) {}

/* Media queries pour les ajustements en dessous de 1000px */
@media (max-width: 1000px) {
    .flex-wrap-reverse {
        flex-direction: column-reverse;
    }
}

@media (max-width: 1000px) {
    .flex-column img {
        width: 100%;
    }

    h2 {
        font-size: 3vw;
    }

    p {
        font-size: 2vw;
    }
}

@media (max-width: 768px) {
    h2 {
        font-size: 4vw;
    }

    p {
        font-size: 2.5vw;
    }



}

@media (max-width: 480px) {
    h2 {
        font-size: 5vw;
    }

    p {
        font-size: 4vw;
    }
}