.footer {
    background-color: #E9F0F0;
    padding: 2rem;
}

.div-size{
    width: 300px;
}

.f-footer{
    font-size: 12px;
}

.logo-reseau{
    height: auto;
    width: 40px;
}

.arcep-logo{
    height: auto;
    width: 400px;
}

.border-logo{
    border: 2px solid black;
    padding: 2rem;
    border-radius: 18px 0px 18px 0px;
}

.footer-logo{
    height: auto;
    width: 300px;
}

.info {
    padding: 0 auto;
    margin: 0 auto;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 576px) {
    .info {
        font-size: 15px;
        max-width: 20rem;
        margin-top: 4rem;
        margin-left: 0;
    }
}