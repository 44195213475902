.form {
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
    padding: 1.5rem;
    max-width: 500px;

}


.form-title {
    display: flex;
    justify-content: center;
    font-size: 20px;
    color: #000;
    margin: 0 !important;

}

.button-form {
    display: flex;
    justify-content: center;
}

.input {
    border: none;
    background-color: #F2F2FF;
}

@media screen and (max-width: 576px) {
    .form {
        width: 100%;
        height: 100%;
        margin: 0;
    }
}